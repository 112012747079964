import React from "react"
import * as styles from "../paymentTypes.module.scss"
import PaypalIcon from "../../../../images/Icons/paypal-icon.svg"
import ChckboxIcon from "../../../../images/Icons/check-icon.svg"

const Paypal = ({ active }) => {
  return (
    <>
      <div
        className={`${styles.item}  ${active && styles.active}`}
      >
        <label className={styles.btn}>
          <PaypalIcon />
          Paypal
          {active && <ChckboxIcon className={styles.activeIcon} />}
        </label>
        {active && (
          <>
            <hr />
            <p>
              Sie werden zu Paypal weitergeleitet um mit Ihrem Paypal Account zu
              bezahlen.
            </p>
          </>
        )}
      </div>
    </>
  )
}

export default Paypal

export const Paypal_Icon = PaypalIcon;
